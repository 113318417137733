import type { GqlOps } from '#gql'
import { hash } from 'ohash'

export const useCachedGql = async (...args: any[]) => {
  const toReactive = (v: any) => (v && isRef(v) ? v : reactive(v))
  const options =
    (typeof args?.[0] !== 'string' && 'options' in args?.[0]
      ? args[0].options
      : args[2]) ?? {}
  const operation =
    (typeof args?.[0] !== 'string' && 'operation' in args?.[0]
      ? args[0].operation
      : args[0]) ?? undefined
  const variables =
    (typeof args?.[0] !== 'string' && 'variables' in args?.[0]
      ? toReactive(args[0].variables)
      : args[1] && toReactive(args[1])) ?? undefined
  if (variables) {
    options.watch = options.watch || []
    options.watch.push(variables)
  }
  const key = `gql:data:${hash({ operation, variables })}`

  const { enabled: previewModeEnabled } = usePreviewMode()
  const revalidateHeader = useRuntimeConfig().public.VERCEL_BYPASS_TOKEN
  const defaultHeaders = {
    accept: 'application/json',
  }

  return useAsyncData(
    key,
    () =>
      $fetch(`/api/graphql`, {
        // pass variables as JSON (normal query params would not be typed)
        query: {
          _variables: JSON.stringify(unref(variables)),
          _operation: operation,
        },
        ...(previewModeEnabled.value
          ? {
              headers: {
                ...defaultHeaders,
                'x-prerender-revalidate':
                  typeof revalidateHeader === 'string' ? revalidateHeader : '',
              },
            }
          : { headers: defaultHeaders }),
      }),
    options
  )
}
